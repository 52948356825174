import {
  Query,
  SanityThankYouConnection,
  SitePageContext,
} from "@graphql-types";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Hero from "@components/shared/hero";
import LayOut from "@components/layout";

interface Data extends Query {
  altRegions: SanityThankYouConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: SitePageContext;
}

const ThankyouTemplate = (props: Props) => {
  const { data } = props;

  if (!data) {
    return null;
  }

  const page = data.allSanityThankYou.nodes[0];

  return (
    <>
      <LayOut>
        <Hero singleHeroContent={page.hero} />
      </LayOut>
    </>
  );
};

export default ThankyouTemplate;

export const query = graphql`
  query thankyouQuery($iban: String) {
    allSanityThankYou(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
      }
    }
    altRegions: allSanityThankYou {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;
